@charset "UTF-8";

/**======================================
	top
======================================**/

//--------------------------------------
//	setting
//--------------------------------------

@import '../common/setting';

/*--------------------------------------
	common
---------------------------------------*/

.heading_top {
	font-size: 2.2rem;
	line-height: 1.3em;
	color: #000;
	@media print, screen and (min-width: 600px) {
	}
}

/*--------------------------------------
	promo
---------------------------------------*/

#promo_wrap {
	& {
		z-index: 2;
		margin-bottom: 0;
		border-bottom: 5px solid color(theme, brown);
		//position: relative;
		width: 100%;
		overflow: hidden;
		text-align: center;
		.promo {
			width: 100%;
			text-align: center;
			.promo_cont {
				position: relative;
				.promo_pic {
					position: relative;
					display: block;
					&::before {
						content: '';
						display: block;
					}
					img {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
						width: 100%;
						height: 100%;
						font-family: 'object-fit: cover;';
						object-fit: cover;
					}
				}
			}
		}
		// slick overwrite
		.slick_btn_wrap {
			// position: relative;
			// z-index: 100;
			height: 0;
			.slick_prev,
			.slick_next {
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 2;
				display: block;
				margin: auto 0;
				border: 3px solid color(theme, brown);
				border-radius: 50%;
				background: color(theme, yellow);
				text-decoration: none;
				font-size: 0;
				// text-indent: -9999px;
				cursor: pointer;
				&::before {
					position: absolute;
					top: 50%;
					left: 50%;
					text-indent: 0;
					font-size: 1.2rem;
					font-weight: bold;
					color: color(theme, brown);
					transform: translate(-50%, -50%);
				}
			}
			.slick_prev {
				@include fontawesome('\f053', 1.2rem, '', '', 'inline');
				position: absolute;
				&::before {
					width: 1.2rem;
					height: 1.2rem;
				}
			}
			.slick_next {
				@include fontawesome('\f054', 1.2rem, '', '', 'inline');
				position: absolute;
				&::before {
					width: 1.2rem;
					height: 1.2rem;
				}
			}
		}
		.slick-dots {
			position: absolute;
			bottom: 0;
			left: 0;
			li {
				button {
					&::before {
						border-radius: 50%;
						background: white;
						opacity: 1;
						color: transparent;
					}
				}
			}
			li.slick-active {
				button {
					&::before {
						background: color(theme, yellow);
					}
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.promo {
			.promo_cont {
				.promo_pic {
					&::before {
						padding-top: 100%;
					}
				}
			}
		}
		// slick overwrite
		.slick_btn_wrap {
			.slick_prev,
			.slick_next {
				width: 22px;
				height: 22px;
				&::before {
					width: 1.2rem;
					height: 1.2rem;
					line-height: 1.2rem;
				}
			}
			.slick_prev {
				// top: 174px;
				left: 5%;
			}
			.slick_next {
				right: 5%;
				// bottom: 174px;
			}
		}
		.slick-dots {
			li {
				width: 14px;
				height: 14px;
				button {
					&::before {
						border: 3px solid color(theme, brown);
						width: 14px;
						height: 14px;
					}
				}
			}
		}
	}
	@media print, screen and (min-width: 600px) and (max-width: 960px) {
		.promo {
			.promo_cont {
				.promo_pic {
					&::before {
						padding-top: 450px;
					}
					img {
						/* for ie11& edge */
						font-family: 'object-fit: cover;';
					}
				}
			}
		}
		// slick overwrite
		.slick_btn_wrap {
			.slick_prev,
			.slick_next {
				margin: auto 0;
				width: 40px;
				height: 40px;
				&::before {
					width: 1.8rem;
					height: 1.8rem;
					font-size: 1.8rem;
					line-height: 1.8rem;
				}
			}
			.slick_prev {
				// top: 205px;
				left: 20px;
			}
			.slick_next {
				right: 20px;
				// bottom: 205px;
			}
		}
		.slick-dots {
			margin-bottom: 0.75em;
			li {
				width: 20px;
				height: 20px;
				button {
					&::before {
						border: 5px solid color(theme, brown);
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
	@media print, screen and (min-width: 960px) {
		.promo {
			width: 1200px !important;
			.promo_cont {
				.promo_pic {
					&::before {
						padding-top: 420px;
					}
				}
			}
		}
		// slick overwrite
		.slick_btn_wrap {
			margin: 0 auto;
			max-width: 1200px;
			.slick_prev,
			.slick_next {
				border-width: 5px;
				width: 40px;
				height: 40px;
				&::before {
					width: 2.2rem;
					height: 2.2rem;
					font-size: 2.2rem;
					line-height: 2.2rem;
				}
			}
			.slick_prev {
				// top: 190px;
				left: 20px;
				&::before {
					left: 45%;
				}
			}
			.slick_next {
				right: 20px;
				// bottom: 190px;
				&::before {
					left: 55%;
				}
			}
		}
		.slick-dots {
			margin-bottom: 1.5em;
			li {
				margin: 0 0.5em;
				width: 20px;
				height: 20px;
				button {
					&::before {
						border: 5px solid color(theme, brown);
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
	@media print, screen and (min-width: 1800px) {
		.promo {
			.promo_cont {
				.promo_pic {
					img {
						/* for ie & edge */
						//object-fit: contain;
						//font-family: 'object-fit: contain;';
					}
				}
			}
		}
	}
}

/*--------------------------------------
	top_contents
---------------------------------------*/

#top_contents {
	& {
		// base overwrite
		padding-top: 5% !important;
		padding-bottom: 5% !important;

		/* コロナ告知のために一時的に追加 20-04-23 */
		#top_corona_area {
			.box {
				.part {
					margin-bottom: 20px;
					.image_cont {
						.corona_pic {
							img {
								width: 100%;
								height: auto;
							}
							a:hover {
								opacity: 0.6;
								transition: all 0.6s;
							}
						}
					}
				}
				@media screen and (max-width: 600px) {
					margin-bottom: 20px;
					.part {
						margin-bottom: 15px;
					}
				}
			}
		}

		/* top_opencampus_area */
		#top_opencampus_area {
			.box {
				margin-bottom: 20px !important;
				.part {
					.image_cont {
						.opencampus_pic {
							margin-bottom: 0;
							border-color: color(theme, brown);
							border-style: solid;
							border-width: 4px;
							background: white;
							&::before {
								padding-top: 35%;
							}
							a {
								img {
									transition: all 0.6s;
								}
							}
							a:hover {
								img {
									opacity: 0.6;
								}
							}
							&.banner {
								border-color: color(theme, green);
							}
						}
						.opencampus_pic.test {
							border-color: color(theme, blue);
						}
					}
				}
			}
		}
		/* top_pickup_area */
		#top_pickup_area {
			.box {
				.pickup_list {
					.pickup_list_item {
						position: relative;
						margin-bottom: 5%;
						background: white;
						.pickup_pic {
							margin-bottom: 0;
							border-style: solid;
							border-width: 4px;
							&::before {
								padding-top: 35%;
							}
							img {
								transition: all 0.6s;
							}
						}
						.heading03_ballon {
							position: absolute;
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							font-size: 1.6rem;
							font-family: font(shirokuma);
						}
					}
					.pickup_list_item:hover {
						.pickup_pic {
							img {
								opacity: 0.6;
							}
						}
					}
					.pickup_list_item.voice {
						.pickup_pic {
							border-color: color(theme, lightblue);
						}
						.heading03_ballon {
							bottom: 4%;
							left: 3%;
							width: 28%;
							height: 76%;
							background: url('../../images/top/pickup_voice_bg.png') no-repeat;
							background-size: contain;
							color: color(theme, lightblue);
							letter-spacing: -0.2em;
						}
					}
					.pickup_list_item.campuslife {
						.pickup_pic {
							border-color: color(theme, purple);
						}
						.heading03_ballon {
							right: 3%;
							bottom: 14%;
							width: 38%;
							height: 68%;
							background: url('../../images/top/pickup_campuslife_bg.png') no-repeat;
							background-size: contain;
							line-height: 1.2em;
							color: color(theme, purple);
							letter-spacing: -0.2em;
						}
					}
					.pickup_list_item.ogob {
						.pickup_pic {
							border-color: color(theme, green);
						}
						.heading03_ballon {
							bottom: 5%;
							left: 3%;
							padding-top: 0.5em;
							width: 41%;
							height: 73%;
							background: url('../../images/top/pickup_ogob_bg.png') no-repeat;
							background-size: contain;
							line-height: 1.2em;
							color: color(theme, green);
							letter-spacing: -0.2em;
						}
					}
				}
			}
		}

		/* top_learn_area */
		#top_learn_area {
			.box {
				.learn_list {
					/* 2103以降は非表示に */
					justify-content: center !important;
					.learn_list_item {
						background: white;
						.learn_pic {
							margin-bottom: 0;
							border-style: solid;
							border-width: 4px;
							border-bottom-width: 0;
							transition: all 0.6s;
							&::before {
								padding-top: 56%;
							}
						}
						.heading02_ribbon_white {
							padding: calc(0.5em + 4px) 0 0.5em;
							text-align: center;
							font-size: 1.6rem;
							transition: all 0.6s;
							span {
								position: relative;
								display: inline-block;
								padding: 0.1em 0.25em;
								width: 70%;
								background: white;
								font-size: inherit;
								font-family: font(shirokuma);
								color: inherit;
								&::before,
								&::after {
									content: '';
									position: absolute;
									top: 0;
									width: 12px;
									height: 100%;
								}
								&::before {
									left: 0;
									background: url('../../images/common/decoration/heading02_ribbon_white_left.svg') no-repeat, 12px 100%;
									transform: translateX(-10px);
								}
								&::after {
									right: 0;
									background: url('../../images/common/decoration/heading02_ribbon_white_right.svg') no-repeat, 12px 100%;
									transform: translateX(10px);
								}
							}
						}
					}
					.learn_list_item:hover {
						.learn_pic {
							opacity: 0.6;
						}
						.heading02_ribbon_white {
							opacity: 0.6;
						}
					}
					.learn_list_item.child {
						.learn_pic {
							border-color: color(theme, pink);
						}
						.heading02_ribbon_white {
							background: color(theme, pink);
							color: color(theme, pink);
						}
					}
					.learn_list_item.welfare {
						.learn_pic {
							border-color: color(theme, orange);
						}
						.heading02_ribbon_white {
							background: color(theme, orange);
							color: color(theme, orange);
						}
					}
				}
				.learn_part {
					padding: 10px;
					background: color(theme, pink);
					a {
						display: flex;
						justify-content: space-between;
						.list_cont:first-child {
							flex-basis: 60%;
							.object_fit {
								margin: 0;
								img {
									transition: all 0.6s;
								}
							}
						}
						.list_cont:last-child {
							position: relative;
							flex-basis: 40%;
							.heading02_ribbon_white {
								position: absolute;
								top: 50%;
								left: 50%;
								text-align: center;
								font-size: 3.3rem;
								transition: all 0.6s;
								transform: translateX(-50%) translateY(-50%);
								span {
									position: relative;
									display: inline-block;
									padding: 0 2em;
									background: white;
									font-size: inherit;
									font-family: font(shirokuma);
									color: color(theme, pink);
									white-space: nowrap;
									&::before,
									&::after {
										content: '';
										position: absolute;
										top: 0;
										width: 12px;
										height: 100%;
									}
									&::before {
										left: 0;
										background: url('../../images/common/decoration/heading02_ribbon_white_left.svg') no-repeat, 12px 100%;
										transform: translateX(-10px);
									}
									&::after {
										right: 0;
										background: url('../../images/common/decoration/heading02_ribbon_white_right.svg') no-repeat, 12px 100%;
										transform: translateX(10px);
									}
								}
							}
						}
						@media screen and (max-width: 600px) {
							.list_cont:first-child {
								flex-basis: 50%;
							}
							.list_cont:last-child {
								flex-basis: 45%;
								.heading02_ribbon_white {
									font-size: 2rem;
									span {
										padding: 0 1em;
									}
								}
							}
						}
					}
					a:hover {
						.list_cont:first-child {
							.object_fit {
								img {
									opacity: 0.8;
									transition: all 0.6s;
								}
							}
						}
					}
				}
			}
		}

		/* top_news_weblog_area */
		#top_news_weblog_area {
			.box {
				.news_weblog_part {
					.list_cont {
						.heading02_ribbon_green {
							margin: 1.5em 0;
							text-align: center;
							font-size: 1.6rem;
							span {
								position: relative;
								display: inline-block;
								padding: 0.1em 0.25em;
								width: 80%;
								background: color(theme, green);
								font-size: inherit;
								font-family: font(shirokuma);
								color: white;
								&::before,
								&::after {
									content: '';
									position: absolute;
									top: 0;
									width: 15px;
									height: 100%;
								}
								&::before {
									left: 0;
									background: url('../../images/common/decoration/heading02_ribbon_green_left.svg') no-repeat, 15px 100%;
									transform: translateX(-13px);
								}
								&::after {
									right: 0;
									background: url('../../images/common/decoration/heading02_ribbon_green_right.svg') no-repeat, 15px 100%;
									transform: translateX(13px);
								}
							}
						}
					}
					.top_news_archive,
					.top_weblog_archive {
						position: relative;
						margin-bottom: calc(5% + 1.5em);
						padding: 1em 1.5em;
						background: white;
						&::before,
						&::after {
							content: '';
							position: absolute;
							left: 0;
							width: 100%;
							height: 1.5em;
						}
						&::before {
							top: -1.5em;
							background: linear-gradient(45deg, white 10px, transparent 0), linear-gradient(315deg, white 10px, transparent 0);
							background-size: 1.5em 1.5em;
						}
						&::after {
							bottom: -1.5em;
							background: linear-gradient(135deg, white 10px, transparent 0), linear-gradient(225deg, white 10px, transparent 0);
							background-size: 1.5em 1.5em;
						}
					}
					.top_news_archive {
						.news_cont {
							.news_list {
								display: flex;
								flex-wrap: nowrap;
								padding: 1.5em 0;
								.news_date {
									flex-basis: 6em;
									margin-right: 2em;
									max-width: 6em;
									font-size: 1.1rem;
									time {
										font-size: inherit;
									}
								}
								.news_title {
									flex: 1;
									font-size: 1.1rem;
									color: color(text, base);
									a {
										font-size: inherit;
										color: inherit;
										transition: all 0.6s;
									}
									a:hover {
										opacity: 0.6;
									}
								}
							}
							.news_list + .news_list {
								border-top: 1px solid rgba(color(theme, brown), 0.3);
							}
						}
					}
					.top_weblog_archive {
						.weblog_cont {
							.weblog_list {
								cursor: pointer;
								transition: all 0.6s;
								.weblog_pic {
									border: 1px solid rgba(0, 0, 0, 0.05);
									&::before {
										padding-top: 87%;
									}
								}
								.weblog_date {
									font-size: 1.1rem;
									color: color(text, base);
									time {
										font-size: inherit;
										color: inherit;
									}
								}
								.weblog_title {
									font-size: 1.1rem;
									color: color(text, base);
								}
							}
							.weblog_list:hover {
								opacity: 0.6;
							}
						}
					}
				}
			}
		}

		/* top_emergency_area */
		#top_emergency_area {
			.box {
				margin-right: 5%;
				margin-left: 5%;
				padding: 1.5em 1em;
				border-top: 5px solid color(theme, green);
				border-bottom: 5px solid color(theme, green);
				background: white;
				.heading03 {
					text-align: center;
				}
				.top_news_archive {
					margin-bottom: 0;
					.news_cont {
						margin-bottom: 0;
						.news_list {
							display: flex;
							flex-wrap: nowrap;
							padding: 1.5em 0;
							border-bottom: 1px solid rgba(color(theme, brown), 0.3);
							.news_date {
								flex-basis: 6em;
								margin-right: 2em;
								max-width: 6em;
								font-size: 1.1rem;
								time {
									font-size: inherit;
								}
							}
							.news_cont_inner {
								flex: 1;
								.news_title {
									font-size: 1.1rem;
									font-weight: bold;
									color: color(theme, brown);
								}
								.news_text {
									margin-top: 0.5em;
									font-size: 1.1rem;
								}
							}
						}
					}
				}
			}
		}

		/* top_link_area */
		#top_link_area {
			.box {
				.link_list {
					.link_list_item {
						.pic {
							transition: all 0.6s;
						}
					}
					.link_list_item:hover {
						.pic {
							opacity: 0.6;
						}
					}
					.faq {
						margin: 0 auto 2em;
						max-width: 200px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		/* top_opencampus_area */
		#top_opencampus_area {
			margin-bottom: 5%;
			.box {
				margin-bottom: 0;
				.part {
					margin-bottom: 0;
					.image_cont {
						.opencampus_pic {
						}
					}
				}
			}
		}

		/* top_emergency_area */
		#top_emergency_area {
			margin-bottom: 5%;
			.box {
				margin-bottom: 0;
				.part {
					margin-bottom: 0;
				}
			}
		}

		/* top_link_area */
		#top_link_area {
			margin-bottom: 0;
			.box {
				margin-bottom: 0;
				.link_list {
					// margin-bottom: 0;
					.link_list_item {
						flex-basis: 48.5%;
						margin-right: 3%;
						margin-bottom: 3%;
						max-width: 48.5%;
						.pic {
							margin-bottom: 0;
						}
					}
					.link_list_item:nth-child(2n) {
						margin-right: 0;
					}
				}
				.faq {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 auto;
					max-width: 400px;
				}
			}
		}
	}
	@media print, screen and (min-width: 600px) {
		// base overwrite
		padding-top: 20px !important;
		padding-bottom: 20px !important;

		/* top_opencampus_area */
		#top_opencampus_area {
			margin-bottom: 20px;
			.box {
				margin-bottom: 0;
				.part {
					margin-bottom: 0;
					.image_cont {
						.opencampus_pic {
							border-width: 10px;
						}
					}
				}
			}
		}

		/* top_pickup_area */
		#top_pickup_area {
			margin-bottom: 20px;
			.box {
				margin-bottom: 0;
				.pickup_list {
					margin-bottom: 0;
					.pickup_list_item {
						margin-bottom: 20px;
						.pickup_pic {
							border-width: 10px;
						}
						.heading03_ballon {
							font-size: 3.2rem;
						}
					}
					.pickup_list_item.voice {
						.pickup_pic {
						}
						.heading03_ballon {
						}
					}
					.pickup_list_item.campuslife {
						.pickup_pic {
						}
						.heading03_ballon {
						}
					}
					.pickup_list_item.ogob {
						.pickup_pic {
						}
						.heading03_ballon {
						}
					}
				}
			}
		}

		/* top_learn_area */
		#top_learn_area {
			margin-bottom: 20px;
			.box {
				margin-bottom: 0;
				.learn_list {
					flex-wrap: nowrap;
					justify-content: space-between;
					margin-bottom: 0;
					.learn_list_item {
						flex-basis: 50%;
						margin-right: 20px;
						max-width: 50%;
						.learn_pic {
							border-width: 10px;
							border-bottom-width: 0;
						}
						.heading02_ribbon_white {
							padding: 0.75em 0;
							font-size: 2.8rem;
						}
					}
					.learn_list_item:nth-child(2n) {
						margin-right: 0;
					}
					.learn_list_item.child {
						.learn_pic {
						}
						.heading02_ribbon_white {
						}
					}
					.learn_list_item.welfare {
						.learn_pic {
						}
						.heading02_ribbon_white {
						}
					}
				}
			}
		}

		/* top_news_weblog_area */
		#top_news_weblog_area {
			.box {
				margin-bottom: 0;
				.news_weblog_part {
					flex-wrap: nowrap;
					justify-content: space-between;
					margin-bottom: 0;
					> .list_cont {
						flex-basis: 50%;
						margin-bottom: calc(20px + 1.5em);
						max-width: 50%;
						.heading02_ribbon_green {
						}
					}
					.top_news_archive {
						margin-right: 20px;
						.news_cont {
							.news_list {
								.news_date {
									time {
									}
								}
								.news_title {
									a {
									}
								}
							}
						}
					}
					.top_weblog_archive {
						margin-right: 0;
						.weblog_cont {
							.weblog_list {
								.weblog_pic {
								}
								.weblog_date {
									time {
									}
								}
								.weblog_title {
								}
							}
						}
					}
				}
			}
		}

		/* top_emergency_area */
		#top_emergency_area {
			margin-bottom: 20px;
			.box {
				margin-right: 0;
				margin-bottom: 0;
				margin-left: 0;
				padding-right: 1.5em;
				padding-left: 1.5em;
				.heading03 {
				}
				.top_news_archive {
					.news_cont {
						.news_list {
							.news_date {
								time {
								}
							}
							.news_cont_inner {
								.news_title {
								}
								.news_text {
								}
							}
						}
					}
				}
			}
		}

		/* top_link_area */
		#top_link_area {
			.box {
				margin-bottom: 0;
				.link_list {
					// margin-bottom: 0;
					.link_list_item {
						margin-bottom: 0;
						.pic {
							margin-bottom: 0;
						}
					}
				}
				.faq {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 auto 1rem;
					max-width: 300px;
				}
			}
		}
	}
	@media print, screen and (min-width: 960px) {
		// base overwrite
		padding-top: 60px !important;
		padding-bottom: 0 !important;

		/* top_opencampus_area */
		#top_opencampus_area {
			margin-bottom: 60px;
			.box {
				.part {
					.image_cont {
					}
				}
			}
		}

		/* top_pickup_area */
		#top_pickup_area {
			margin-bottom: 60px;
			.box {
				.pickup_list {
					.pickup_list_item {
						margin-bottom: 0;
						.pickup_pic {
							&::before {
								padding-top: 100%;
							}
						}
						.heading03_ballon {
							font-size: 2.8rem;
						}
					}
					.pickup_list_item.voice {
						.pickup_pic {
						}
						.heading03_ballon {
							bottom: 13%;
							left: 5%;
							padding-right: 0.5em;
							width: 46%;
							height: 45%;
							letter-spacing: -0.3em;
						}
					}
					.pickup_list_item.campuslife {
						.pickup_pic {
						}
						.heading03_ballon {
							top: 12%;
							right: 0;
							width: 55%;
							height: 36%;
						}
					}
					.pickup_list_item.ogob {
						.pickup_pic {
						}
						.heading03_ballon {
							bottom: 5%;
							left: 7%;
							width: 60%;
							height: 39%;
						}
					}
				}
			}
		}

		/* top_learn_area */
		#top_learn_area {
			margin-bottom: 60px;
			.box {
				.learn_list {
					flex-wrap: nowrap;
					justify-content: space-between;
					margin-bottom: 0;
					.learn_list_item {
						flex-basis: 50%;
						margin-right: 60px;
						max-width: 50%;
						.learn_pic {
						}
						.heading02_ribbon_white {
							font-size: 3.5rem;
						}
					}
					.learn_list_item:nth-child(2n) {
						margin-right: 0;
					}
					.learn_list_item.child {
						.learn_pic {
						}
						.heading02_ribbon_white {
						}
					}
					.learn_list_item.welfare {
						.learn_pic {
						}
						.heading02_ribbon_white {
						}
					}
				}
			}
		}

		/* top_news_weblog_area */
		#top_news_weblog_area {
			.box {
				.news_weblog_part {
					flex-wrap: nowrap;
					justify-content: space-between;
					> .list_cont {
						flex-basis: 50%;
						margin-bottom: calc(60px + 1.5em);
						max-width: 50%;
						.heading02_ribbon_green {
							font-size: 3.6rem;
							span {
								letter-spacing: -0.2em;
							}
						}
					}
					.top_news_archive {
						margin-right: 60px;
						.news_cont {
							.news_list {
								.news_date {
									font-size: 1.6rem;
									time {
									}
								}
								.news_title {
									font-size: 1.6rem;
									a {
									}
								}
							}
						}
					}
					.top_weblog_archive {
						margin-right: 0;
						.weblog_cont {
							.weblog_list {
								.weblog_pic {
								}
								.weblog_date {
									font-size: 1.4rem;
									time {
									}
								}
								.weblog_title {
									font-size: 1.4rem;
								}
							}
						}
					}
				}
			}
		}

		/* top_emergency_area */
		#top_emergency_area {
			margin-bottom: 60px;
			.box {
				padding: 2em 2em 3em;
				.heading03 {
					font-size: 2.7rem;
				}
				.top_news_archive {
					.news_cont {
						.news_list {
							.news_date {
								font-size: 1.6rem;
								time {
								}
							}
							.news_cont_inner {
								.news_title {
									font-size: 1.6rem;
								}
								.news_text {
									font-size: 1.4rem;
								}
							}
						}
					}
				}
			}
		}

		/* top_link_area */
		#top_link_area {
			margin-bottom: 60px;
			.box {
				.link_list {
					.link_list_item {
						.pic {
						}
					}
				}
				.faq {
					margin: 0 auto 2em;
					max-width: 400px;
				}
			}
		}
	}
}
