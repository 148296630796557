@charset "UTF-8";
/**======================================
	top
======================================**/
@font-face {
  font-weight: 400;
  font-family: "001Shirokuma-Regular";
  font-style: normal;
  -ms-font-feature-settings: "normal";
  src: url("../lib/fonts/shirokuma/001shirokuma-regular.eot"); /* IE9以上用 */
  src: url("../lib/fonts/shirokuma/001shirokuma-regular.woff2") format("woff2"), url("../lib/fonts/shirokuma/001shirokuma-regular.woff") format("woff"), url("../lib/fonts/shirokuma/001shirokuma-regular.otf") format("opentype");
}
/* mixin */
/*
*/
/* mq */
/* base */
/*--------------------------------------
	common
---------------------------------------*/
.heading_top {
  font-size: 2.2rem;
  line-height: 1.3em;
  color: #000;
}
/*--------------------------------------
	promo
---------------------------------------*/
#promo_wrap {
  z-index: 2;
  margin-bottom: 0;
  border-bottom: 5px solid #472f13;
  width: 100%;
  overflow: hidden;
  text-align: center;
}
#promo_wrap .promo {
  width: 100%;
  text-align: center;
}
#promo_wrap .promo .promo_cont {
  position: relative;
}
#promo_wrap .promo .promo_cont .promo_pic {
  position: relative;
  display: block;
}
#promo_wrap .promo .promo_cont .promo_pic::before {
  content: "";
  display: block;
}
#promo_wrap .promo .promo_cont .promo_pic img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover;";
  object-fit: cover;
}
#promo_wrap .slick_btn_wrap {
  height: 0;
}
#promo_wrap .slick_btn_wrap .slick_prev,
#promo_wrap .slick_btn_wrap .slick_next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: block;
  margin: auto 0;
  border: 3px solid #472f13;
  border-radius: 50%;
  background: #fff100;
  text-decoration: none;
  font-size: 0;
  cursor: pointer;
}
#promo_wrap .slick_btn_wrap .slick_prev::before,
#promo_wrap .slick_btn_wrap .slick_next::before {
  position: absolute;
  top: 50%;
  left: 50%;
  text-indent: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #472f13;
  transform: translate(-50%, -50%);
}
#promo_wrap .slick_btn_wrap .slick_prev {
  position: relative;
  position: absolute;
}
#promo_wrap .slick_btn_wrap .slick_prev::before {
  content: "\f053";
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
  font-family: "Font Awesome 5 Pro";
  line-height: 1.2rem;
  display: inline-block;
}
#promo_wrap .slick_btn_wrap .slick_prev::before {
  width: 1.2rem;
  height: 1.2rem;
}
#promo_wrap .slick_btn_wrap .slick_next {
  position: relative;
  position: absolute;
}
#promo_wrap .slick_btn_wrap .slick_next::before {
  content: "\f054";
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
  font-family: "Font Awesome 5 Pro";
  line-height: 1.2rem;
  display: inline-block;
}
#promo_wrap .slick_btn_wrap .slick_next::before {
  width: 1.2rem;
  height: 1.2rem;
}
#promo_wrap .slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
}
#promo_wrap .slick-dots li button::before {
  border-radius: 50%;
  background: white;
  opacity: 1;
  color: transparent;
}
#promo_wrap .slick-dots li.slick-active button::before {
  background: #fff100;
}
@media screen and (max-width: 600px) {
  #promo_wrap .promo .promo_cont .promo_pic::before {
    padding-top: 100%;
  }
  #promo_wrap .slick_btn_wrap .slick_prev,
#promo_wrap .slick_btn_wrap .slick_next {
    width: 22px;
    height: 22px;
  }
  #promo_wrap .slick_btn_wrap .slick_prev::before,
#promo_wrap .slick_btn_wrap .slick_next::before {
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
  }
  #promo_wrap .slick_btn_wrap .slick_prev {
    left: 5%;
  }
  #promo_wrap .slick_btn_wrap .slick_next {
    right: 5%;
  }
  #promo_wrap .slick-dots li {
    width: 14px;
    height: 14px;
  }
  #promo_wrap .slick-dots li button::before {
    border: 3px solid #472f13;
    width: 14px;
    height: 14px;
  }
}
@media print, screen and (min-width: 600px) and (max-width: 960px) {
  #promo_wrap .promo .promo_cont .promo_pic::before {
    padding-top: 450px;
  }
  #promo_wrap .promo .promo_cont .promo_pic img {
    /* for ie11& edge */
    font-family: "object-fit: cover;";
  }
  #promo_wrap .slick_btn_wrap .slick_prev,
#promo_wrap .slick_btn_wrap .slick_next {
    margin: auto 0;
    width: 40px;
    height: 40px;
  }
  #promo_wrap .slick_btn_wrap .slick_prev::before,
#promo_wrap .slick_btn_wrap .slick_next::before {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  #promo_wrap .slick_btn_wrap .slick_prev {
    left: 20px;
  }
  #promo_wrap .slick_btn_wrap .slick_next {
    right: 20px;
  }
  #promo_wrap .slick-dots {
    margin-bottom: 0.75em;
  }
  #promo_wrap .slick-dots li {
    width: 20px;
    height: 20px;
  }
  #promo_wrap .slick-dots li button::before {
    border: 5px solid #472f13;
    width: 20px;
    height: 20px;
  }
}
@media print, screen and (min-width: 960px) {
  #promo_wrap .promo {
    width: 1200px !important;
  }
  #promo_wrap .promo .promo_cont .promo_pic::before {
    padding-top: 420px;
  }
  #promo_wrap .slick_btn_wrap {
    margin: 0 auto;
    max-width: 1200px;
  }
  #promo_wrap .slick_btn_wrap .slick_prev,
#promo_wrap .slick_btn_wrap .slick_next {
    border-width: 5px;
    width: 40px;
    height: 40px;
  }
  #promo_wrap .slick_btn_wrap .slick_prev::before,
#promo_wrap .slick_btn_wrap .slick_next::before {
    width: 2.2rem;
    height: 2.2rem;
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
  #promo_wrap .slick_btn_wrap .slick_prev {
    left: 20px;
  }
  #promo_wrap .slick_btn_wrap .slick_prev::before {
    left: 45%;
  }
  #promo_wrap .slick_btn_wrap .slick_next {
    right: 20px;
  }
  #promo_wrap .slick_btn_wrap .slick_next::before {
    left: 55%;
  }
  #promo_wrap .slick-dots {
    margin-bottom: 1.5em;
  }
  #promo_wrap .slick-dots li {
    margin: 0 0.5em;
    width: 20px;
    height: 20px;
  }
  #promo_wrap .slick-dots li button::before {
    border: 5px solid #472f13;
    width: 20px;
    height: 20px;
  }
}
@media print, screen and (min-width: 1800px) {
  #promo_wrap .promo .promo_cont .promo_pic img {
    /* for ie & edge */
  }
}

/*--------------------------------------
	top_contents
---------------------------------------*/
#top_contents {
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  /* コロナ告知のために一時的に追加 20-04-23 */
  /* top_opencampus_area */
  /* top_pickup_area */
  /* top_learn_area */
  /* top_news_weblog_area */
  /* top_emergency_area */
  /* top_link_area */
}
#top_contents #top_corona_area .box .part {
  margin-bottom: 20px;
}
#top_contents #top_corona_area .box .part .image_cont .corona_pic img {
  width: 100%;
  height: auto;
}
#top_contents #top_corona_area .box .part .image_cont .corona_pic a:hover {
  opacity: 0.6;
  transition: all 0.6s;
}
@media screen and (max-width: 600px) {
  #top_contents #top_corona_area .box {
    margin-bottom: 20px;
  }
  #top_contents #top_corona_area .box .part {
    margin-bottom: 15px;
  }
}
#top_contents #top_opencampus_area .box {
  margin-bottom: 20px !important;
}
#top_contents #top_opencampus_area .box .part .image_cont .opencampus_pic {
  margin-bottom: 0;
  border-color: #472f13;
  border-style: solid;
  border-width: 4px;
  background: white;
}
#top_contents #top_opencampus_area .box .part .image_cont .opencampus_pic::before {
  padding-top: 35%;
}
#top_contents #top_opencampus_area .box .part .image_cont .opencampus_pic a img {
  transition: all 0.6s;
}
#top_contents #top_opencampus_area .box .part .image_cont .opencampus_pic a:hover img {
  opacity: 0.6;
}
#top_contents #top_opencampus_area .box .part .image_cont .opencampus_pic.banner {
  border-color: #45b035;
}
#top_contents #top_opencampus_area .box .part .image_cont .opencampus_pic.test {
  border-color: #008ccf;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item {
  position: relative;
  margin-bottom: 5%;
  background: white;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item .pickup_pic {
  margin-bottom: 0;
  border-style: solid;
  border-width: 4px;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item .pickup_pic::before {
  padding-top: 35%;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item .pickup_pic img {
  transition: all 0.6s;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item .heading03_ballon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.6rem;
  font-family: "001Shirokuma-Regular";
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item:hover .pickup_pic img {
  opacity: 0.6;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item.voice .pickup_pic {
  border-color: #00b2b3;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item.voice .heading03_ballon {
  bottom: 4%;
  left: 3%;
  width: 28%;
  height: 76%;
  background: url("../../images/top/pickup_voice_bg.png") no-repeat;
  background-size: contain;
  color: #00b2b3;
  letter-spacing: -0.2em;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item.campuslife .pickup_pic {
  border-color: #7d4698;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item.campuslife .heading03_ballon {
  right: 3%;
  bottom: 14%;
  width: 38%;
  height: 68%;
  background: url("../../images/top/pickup_campuslife_bg.png") no-repeat;
  background-size: contain;
  line-height: 1.2em;
  color: #7d4698;
  letter-spacing: -0.2em;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item.ogob .pickup_pic {
  border-color: #45b035;
}
#top_contents #top_pickup_area .box .pickup_list .pickup_list_item.ogob .heading03_ballon {
  bottom: 5%;
  left: 3%;
  padding-top: 0.5em;
  width: 41%;
  height: 73%;
  background: url("../../images/top/pickup_ogob_bg.png") no-repeat;
  background-size: contain;
  line-height: 1.2em;
  color: #45b035;
  letter-spacing: -0.2em;
}
#top_contents #top_learn_area .box .learn_list {
  /* 2103以降は非表示に */
  justify-content: center !important;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item {
  background: white;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item .learn_pic {
  margin-bottom: 0;
  border-style: solid;
  border-width: 4px;
  border-bottom-width: 0;
  transition: all 0.6s;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item .learn_pic::before {
  padding-top: 56%;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white {
  padding: calc(0.5em + 4px) 0 0.5em;
  text-align: center;
  font-size: 1.6rem;
  transition: all 0.6s;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white span {
  position: relative;
  display: inline-block;
  padding: 0.1em 0.25em;
  width: 70%;
  background: white;
  font-size: inherit;
  font-family: "001Shirokuma-Regular";
  color: inherit;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white span::before, #top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white span::after {
  content: "";
  position: absolute;
  top: 0;
  width: 12px;
  height: 100%;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white span::before {
  left: 0;
  background: url("../../images/common/decoration/heading02_ribbon_white_left.svg") no-repeat, 12px 100%;
  transform: translateX(-10px);
}
#top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white span::after {
  right: 0;
  background: url("../../images/common/decoration/heading02_ribbon_white_right.svg") no-repeat, 12px 100%;
  transform: translateX(10px);
}
#top_contents #top_learn_area .box .learn_list .learn_list_item:hover .learn_pic {
  opacity: 0.6;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item:hover .heading02_ribbon_white {
  opacity: 0.6;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item.child .learn_pic {
  border-color: #ea6088;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item.child .heading02_ribbon_white {
  background: #ea6088;
  color: #ea6088;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item.welfare .learn_pic {
  border-color: #f08300;
}
#top_contents #top_learn_area .box .learn_list .learn_list_item.welfare .heading02_ribbon_white {
  background: #f08300;
  color: #f08300;
}
#top_contents #top_learn_area .box .learn_part {
  padding: 10px;
  background: #ea6088;
}
#top_contents #top_learn_area .box .learn_part a {
  display: flex;
  justify-content: space-between;
}
#top_contents #top_learn_area .box .learn_part a .list_cont:first-child {
  flex-basis: 60%;
}
#top_contents #top_learn_area .box .learn_part a .list_cont:first-child .object_fit {
  margin: 0;
}
#top_contents #top_learn_area .box .learn_part a .list_cont:first-child .object_fit img {
  transition: all 0.6s;
}
#top_contents #top_learn_area .box .learn_part a .list_cont:last-child {
  position: relative;
  flex-basis: 40%;
}
#top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 3.3rem;
  transition: all 0.6s;
  transform: translateX(-50%) translateY(-50%);
}
#top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white span {
  position: relative;
  display: inline-block;
  padding: 0 2em;
  background: white;
  font-size: inherit;
  font-family: "001Shirokuma-Regular";
  color: #ea6088;
  white-space: nowrap;
}
#top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white span::before, #top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white span::after {
  content: "";
  position: absolute;
  top: 0;
  width: 12px;
  height: 100%;
}
#top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white span::before {
  left: 0;
  background: url("../../images/common/decoration/heading02_ribbon_white_left.svg") no-repeat, 12px 100%;
  transform: translateX(-10px);
}
#top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white span::after {
  right: 0;
  background: url("../../images/common/decoration/heading02_ribbon_white_right.svg") no-repeat, 12px 100%;
  transform: translateX(10px);
}
@media screen and (max-width: 600px) {
  #top_contents #top_learn_area .box .learn_part a .list_cont:first-child {
    flex-basis: 50%;
  }
  #top_contents #top_learn_area .box .learn_part a .list_cont:last-child {
    flex-basis: 45%;
  }
  #top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white {
    font-size: 2rem;
  }
  #top_contents #top_learn_area .box .learn_part a .list_cont:last-child .heading02_ribbon_white span {
    padding: 0 1em;
  }
}
#top_contents #top_learn_area .box .learn_part a:hover .list_cont:first-child .object_fit img {
  opacity: 0.8;
  transition: all 0.6s;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .list_cont .heading02_ribbon_green {
  margin: 1.5em 0;
  text-align: center;
  font-size: 1.6rem;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .list_cont .heading02_ribbon_green span {
  position: relative;
  display: inline-block;
  padding: 0.1em 0.25em;
  width: 80%;
  background: #45b035;
  font-size: inherit;
  font-family: "001Shirokuma-Regular";
  color: white;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .list_cont .heading02_ribbon_green span::before, #top_contents #top_news_weblog_area .box .news_weblog_part .list_cont .heading02_ribbon_green span::after {
  content: "";
  position: absolute;
  top: 0;
  width: 15px;
  height: 100%;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .list_cont .heading02_ribbon_green span::before {
  left: 0;
  background: url("../../images/common/decoration/heading02_ribbon_green_left.svg") no-repeat, 15px 100%;
  transform: translateX(-13px);
}
#top_contents #top_news_weblog_area .box .news_weblog_part .list_cont .heading02_ribbon_green span::after {
  right: 0;
  background: url("../../images/common/decoration/heading02_ribbon_green_right.svg") no-repeat, 15px 100%;
  transform: translateX(13px);
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive,
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive {
  position: relative;
  margin-bottom: calc(5% + 1.5em);
  padding: 1em 1.5em;
  background: white;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive::before, #top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive::after,
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive::before,
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1.5em;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive::before,
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive::before {
  top: -1.5em;
  background: linear-gradient(45deg, white 10px, transparent 0), linear-gradient(315deg, white 10px, transparent 0);
  background-size: 1.5em 1.5em;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive::after,
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive::after {
  bottom: -1.5em;
  background: linear-gradient(135deg, white 10px, transparent 0), linear-gradient(225deg, white 10px, transparent 0);
  background-size: 1.5em 1.5em;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list {
  display: flex;
  flex-wrap: nowrap;
  padding: 1.5em 0;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list .news_date {
  flex-basis: 6em;
  margin-right: 2em;
  max-width: 6em;
  font-size: 1.1rem;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list .news_date time {
  font-size: inherit;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list .news_title {
  flex: 1;
  font-size: 1.1rem;
  color: #472f13;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list .news_title a {
  font-size: inherit;
  color: inherit;
  transition: all 0.6s;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list .news_title a:hover {
  opacity: 0.6;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list + .news_list {
  border-top: 1px solid rgba(71, 47, 19, 0.3);
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list {
  cursor: pointer;
  transition: all 0.6s;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list .weblog_pic {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list .weblog_pic::before {
  padding-top: 87%;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list .weblog_date {
  font-size: 1.1rem;
  color: #472f13;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list .weblog_date time {
  font-size: inherit;
  color: inherit;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list .weblog_title {
  font-size: 1.1rem;
  color: #472f13;
}
#top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list:hover {
  opacity: 0.6;
}
#top_contents #top_emergency_area .box {
  margin-right: 5%;
  margin-left: 5%;
  padding: 1.5em 1em;
  border-top: 5px solid #45b035;
  border-bottom: 5px solid #45b035;
  background: white;
}
#top_contents #top_emergency_area .box .heading03 {
  text-align: center;
}
#top_contents #top_emergency_area .box .top_news_archive {
  margin-bottom: 0;
}
#top_contents #top_emergency_area .box .top_news_archive .news_cont {
  margin-bottom: 0;
}
#top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list {
  display: flex;
  flex-wrap: nowrap;
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(71, 47, 19, 0.3);
}
#top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_date {
  flex-basis: 6em;
  margin-right: 2em;
  max-width: 6em;
  font-size: 1.1rem;
}
#top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_date time {
  font-size: inherit;
}
#top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_cont_inner {
  flex: 1;
}
#top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_cont_inner .news_title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #472f13;
}
#top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_cont_inner .news_text {
  margin-top: 0.5em;
  font-size: 1.1rem;
}
#top_contents #top_link_area .box .link_list .link_list_item .pic {
  transition: all 0.6s;
}
#top_contents #top_link_area .box .link_list .link_list_item:hover .pic {
  opacity: 0.6;
}
#top_contents #top_link_area .box .link_list .faq {
  margin: 0 auto 2em;
  max-width: 200px;
}
@media screen and (max-width: 600px) {
  #top_contents {
    /* top_opencampus_area */
    /* top_emergency_area */
    /* top_link_area */
  }
  #top_contents #top_opencampus_area {
    margin-bottom: 5%;
  }
  #top_contents #top_opencampus_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_opencampus_area .box .part {
    margin-bottom: 0;
  }
  #top_contents #top_emergency_area {
    margin-bottom: 5%;
  }
  #top_contents #top_emergency_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_emergency_area .box .part {
    margin-bottom: 0;
  }
  #top_contents #top_link_area {
    margin-bottom: 0;
  }
  #top_contents #top_link_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_link_area .box .link_list .link_list_item {
    flex-basis: 48.5%;
    margin-right: 3%;
    margin-bottom: 3%;
    max-width: 48.5%;
  }
  #top_contents #top_link_area .box .link_list .link_list_item .pic {
    margin-bottom: 0;
  }
  #top_contents #top_link_area .box .link_list .link_list_item:nth-child(2n) {
    margin-right: 0;
  }
  #top_contents #top_link_area .box .faq {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;
  }
}
@media print, screen and (min-width: 600px) {
  #top_contents {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    /* top_opencampus_area */
    /* top_pickup_area */
    /* top_learn_area */
    /* top_news_weblog_area */
    /* top_emergency_area */
    /* top_link_area */
  }
  #top_contents #top_opencampus_area {
    margin-bottom: 20px;
  }
  #top_contents #top_opencampus_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_opencampus_area .box .part {
    margin-bottom: 0;
  }
  #top_contents #top_opencampus_area .box .part .image_cont .opencampus_pic {
    border-width: 10px;
  }
  #top_contents #top_pickup_area {
    margin-bottom: 20px;
  }
  #top_contents #top_pickup_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_pickup_area .box .pickup_list {
    margin-bottom: 0;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item {
    margin-bottom: 20px;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item .pickup_pic {
    border-width: 10px;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item .heading03_ballon {
    font-size: 3.2rem;
  }
  #top_contents #top_learn_area {
    margin-bottom: 20px;
  }
  #top_contents #top_learn_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_learn_area .box .learn_list {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
  #top_contents #top_learn_area .box .learn_list .learn_list_item {
    flex-basis: 50%;
    margin-right: 20px;
    max-width: 50%;
  }
  #top_contents #top_learn_area .box .learn_list .learn_list_item .learn_pic {
    border-width: 10px;
    border-bottom-width: 0;
  }
  #top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white {
    padding: 0.75em 0;
    font-size: 2.8rem;
  }
  #top_contents #top_learn_area .box .learn_list .learn_list_item:nth-child(2n) {
    margin-right: 0;
  }
  #top_contents #top_news_weblog_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part > .list_cont {
    flex-basis: 50%;
    margin-bottom: calc(20px + 1.5em);
    max-width: 50%;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive {
    margin-right: 20px;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive {
    margin-right: 0;
  }
  #top_contents #top_emergency_area {
    margin-bottom: 20px;
  }
  #top_contents #top_emergency_area .box {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-right: 1.5em;
    padding-left: 1.5em;
  }
  #top_contents #top_link_area .box {
    margin-bottom: 0;
  }
  #top_contents #top_link_area .box .link_list .link_list_item {
    margin-bottom: 0;
  }
  #top_contents #top_link_area .box .link_list .link_list_item .pic {
    margin-bottom: 0;
  }
  #top_contents #top_link_area .box .faq {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
    max-width: 300px;
  }
}
@media print, screen and (min-width: 960px) {
  #top_contents {
    padding-top: 60px !important;
    padding-bottom: 0 !important;
    /* top_opencampus_area */
    /* top_pickup_area */
    /* top_learn_area */
    /* top_news_weblog_area */
    /* top_emergency_area */
    /* top_link_area */
  }
  #top_contents #top_opencampus_area {
    margin-bottom: 60px;
  }
  #top_contents #top_pickup_area {
    margin-bottom: 60px;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item {
    margin-bottom: 0;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item .pickup_pic::before {
    padding-top: 100%;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item .heading03_ballon {
    font-size: 2.8rem;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item.voice .heading03_ballon {
    bottom: 13%;
    left: 5%;
    padding-right: 0.5em;
    width: 46%;
    height: 45%;
    letter-spacing: -0.3em;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item.campuslife .heading03_ballon {
    top: 12%;
    right: 0;
    width: 55%;
    height: 36%;
  }
  #top_contents #top_pickup_area .box .pickup_list .pickup_list_item.ogob .heading03_ballon {
    bottom: 5%;
    left: 7%;
    width: 60%;
    height: 39%;
  }
  #top_contents #top_learn_area {
    margin-bottom: 60px;
  }
  #top_contents #top_learn_area .box .learn_list {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
  #top_contents #top_learn_area .box .learn_list .learn_list_item {
    flex-basis: 50%;
    margin-right: 60px;
    max-width: 50%;
  }
  #top_contents #top_learn_area .box .learn_list .learn_list_item .heading02_ribbon_white {
    font-size: 3.5rem;
  }
  #top_contents #top_learn_area .box .learn_list .learn_list_item:nth-child(2n) {
    margin-right: 0;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part > .list_cont {
    flex-basis: 50%;
    margin-bottom: calc(60px + 1.5em);
    max-width: 50%;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part > .list_cont .heading02_ribbon_green {
    font-size: 3.6rem;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part > .list_cont .heading02_ribbon_green span {
    letter-spacing: -0.2em;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive {
    margin-right: 60px;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list .news_date {
    font-size: 1.6rem;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_news_archive .news_cont .news_list .news_title {
    font-size: 1.6rem;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive {
    margin-right: 0;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list .weblog_date {
    font-size: 1.4rem;
  }
  #top_contents #top_news_weblog_area .box .news_weblog_part .top_weblog_archive .weblog_cont .weblog_list .weblog_title {
    font-size: 1.4rem;
  }
  #top_contents #top_emergency_area {
    margin-bottom: 60px;
  }
  #top_contents #top_emergency_area .box {
    padding: 2em 2em 3em;
  }
  #top_contents #top_emergency_area .box .heading03 {
    font-size: 2.7rem;
  }
  #top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_date {
    font-size: 1.6rem;
  }
  #top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_cont_inner .news_title {
    font-size: 1.6rem;
  }
  #top_contents #top_emergency_area .box .top_news_archive .news_cont .news_list .news_cont_inner .news_text {
    font-size: 1.4rem;
  }
  #top_contents #top_link_area {
    margin-bottom: 60px;
  }
  #top_contents #top_link_area .box .faq {
    margin: 0 auto 2em;
    max-width: 400px;
  }
}