@charset "utf-8";
//--------------------------------------
//	setting
//--------------------------------------
// color
$color_brown: #472f13;
$color_blue: #008ccf;
$color_lightblue: #00b2b3;
$color_yellow: #fff100;
$color_green: #45b035;
$color_orange: #f08300;
$color_pink: #ea6088;
$color_purple: #7d4698;
$color_creamyellow: #fffde5;
$color: (
	theme: (
		brown: $color_brown,
		blue: $color_blue,
		lightblue: $color_lightblue,
		yellow: $color_yellow,
		green: $color_green,
		orange: $color_orange,
		pink: $color_pink,
		purple: $color_purple,
		creamyellow: $color_creamyellow,
	),
	border: (
		base: $color_brown,
		pink: $color_pink,
	),
	bg: (
		base: white,
	),
	text: (
		base: $color_brown,
		form: #231815,
	),
	list: (
		base: $color_green,
	),
	button: (
		base: $color_brown,
		border: $color_brown,
	),
);
$radius: (
	pc: 2em,
	sp: 2em,
);

@font-face {
	font-weight: 400;
	font-family: '001Shirokuma-Regular';
	font-style: normal;
	-ms-font-feature-settings: 'normal';
	src: url('../lib/fonts/shirokuma/001shirokuma-regular.eot'); /* IE9以上用 */
	src: url('../lib/fonts/shirokuma/001shirokuma-regular.woff2') format('woff2'), url('../lib/fonts/shirokuma/001shirokuma-regular.woff') format('woff'), url('../lib/fonts/shirokuma/001shirokuma-regular.otf') format('opentype');
}
$font: (
	base: '"ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic ProN, Hiragino Kaku Gothic Pro, YuGothic, Arial, "メイリオ", Meiryo, sans-serif',
	mincho: 'Hiragino Mincho ProN, "HGS明朝E", serif',
	shirokuma: '"001Shirokuma-Regular"',
);

/* mixin */

@mixin cont_in_wrap {
	& {
		position: relative;
		margin-right: auto;
		margin-left: auto;
	}
	@media print, screen and (min-width: 600px) {
		padding-right: 20px;
		padding-left: 20px;
		max-width: 1200px;
	}
	@media print, screen and (min-width: 900px) {
		padding-right: 40px;
		padding-left: 40px;
	}
}

@mixin button_style_sp {
	position: relative;
	display: block;
	padding: 0;
	overflow: hidden;
	box-shadow: 0 1px 2px rgba(black, 0.2);
	text-decoration: none;
	color: white;
	cursor: pointer;
	span {
		display: block;
		padding: 0.5em 1em;
		border: 3px solid color(button, border);
		background-color: color(button, base);
		text-align: center;
		text-overflow: ellipsis;
		font-weight: bold;
		color: inherit;
		white-space: nowrap;
	}
	&,
	&::before,
	&::after {
		color: color(text, light);
	}
	&:visited span {
		color: color(text, light);
	}
	&:active span {
		border: 1px solid color(button, border);
		background: button;
		box-shadow: 0 1px 2px rgba(black, 0.6);
	}
}

@mixin button_style_pc {
	&,
	&:visited {
		display: inline-block;
		padding: 0;
		outline: none;
		text-decoration: none !important;
		color: white;
		cursor: pointer;
		transition: all 0.5s;
		span {
			display: block;
			padding: 0.6em 3em;
			border: 3px solid color(button, border);
			background: color(button, base);
			text-align: center;
			color: inherit;
			transition: all 0.5s;
		}
		&::before,
		&::after {
			color: color(text, light);
			transition: all 0.5s;
		}
	}
	&:hover {
		span {
		}
	}
}

// fontawesome
@mixin fontawesome($content: '\f105', $size: 22px, $left_right: '', $left_right_position: '', $type: 'block', $brand: 'pro', $before_after: '&::before') {
	position: relative;
	#{ $before_after } {
		content: $content;
		width: $size;
		height: $size;
		font-size: $size;
		@if $brand== 'brand' {
			font-family: 'Font Awesome 5 Brands';
		} @else {
			font-family: 'Font Awesome 5 Pro';
		}
		line-height: $size;
		@if $type== 'inline' {
			display: inline-block;
		} @else if $type== 'self' {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			margin-top: calc($size / 2 * -1);
			margin-left: calc($size / 2 * -1);
		} @else {
			position: absolute;
			top: 50%;
			display: block;
			margin-top: calc($size / 2 * -1);
		}
		@if $left_right== 'left' {
			left: $left_right_position;
		} @else if $left_right== 'right' {
			right: $left_right_position;
		}
	}
}

@mixin fuchidori_text($fuchidori_color: '#000', $border_width: 2px) {
	// 1px || 2px
	@if $border_width == '1px' {
		text-shadow: $fuchidori_color 1px 1px 0, $fuchidori_color -1px -1px 0, $fuchidori_color -1px 1px 0, $fuchidori_color 1px -1px 0, $fuchidori_color 0 1px 0, $fuchidori_color 0-1px 0, $fuchidori_color -1px 0 0, $fuchidori_color 1px 0 0;
	} @else {
		text-shadow: $fuchidori_color 2px 0, $fuchidori_color -2px 0, $fuchidori_color 0 -2px, $fuchidori_color 0 2px, $fuchidori_color 2px 2px, $fuchidori_color -2px 2px, $fuchidori_color 2px -2px, $fuchidori_color -2px -2px, $fuchidori_color 1px 2px, $fuchidori_color -1px 2px, $fuchidori_color 1px -2px, $fuchidori_color -1px -2px, $fuchidori_color 2px 1px, $fuchidori_color -2px 1px, $fuchidori_color 2px -1px, $fuchidori_color -2px -1px;
	}
}

//--------------------------------------
//	module
//--------------------------------------
// functions

/*
*/

@function color($k, $kk) {
	$temp: map-get($color, $k);
	@return map-get($temp, $kk);
}

@function radius($k: pc) {
	@return map-get($radius, $k);
}

@function font($k: base) {
	@return unquote(map-get($font, $k));
}

@function height_header($k, $kk) {
	@return map-get(map-get($height_header, $k), $kk);
}

@mixin clearfix {
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

/* mq */
$breakpoint: 600, 960;
/* base */
$max_pc_width: 1200px;
$narrow_pc_width: 900px;
$compact_pc_width: 700px;

// prettier-ignore
$_media_query: (
	_sp: 'screen and (max-width: ' + (nth($breakpoint, 1) - 1) + 'px)',
	tb_: 'print, screen and (min-width: ' + nth($breakpoint, 1) + 'px)',
	tb: 'screen and (min-width: ' + nth($breakpoint, 1) + 'px) and (max-width: ' + ( nth($breakpoint, 2) - 1 ) + 'px)',
	_tb: 'screen and (max-width: ' + ( nth($breakpoint, 2) - 1 ) + 'px)',
	pc_: 'print, screen and (min-width: ' + nth($breakpoint, 2) + 'px)',
	lg_: 'screen and (min-width: ' + $max_pc_width + ')',
) !default;

@mixin mq($media: all) {
	@if $media == 'all' {
		@content;
	} @else {
		@media #{ map-get($_media_query, $media) } {
			@content;
		}
	}
}
